
  import { defineComponent } from "vue";
  import Card from "primevue/card";
  import Button from "primevue/button";
  import * as FacebookServices from "../api/helpers/Facebook";
  //import AdsMobile from "../components/AdsMobile.vue";
  export default defineComponent({
    name: "Home",
    components: {
      Card,
      Button,
      //AdsMobile
    },
    mounted() {
      FacebookServices.pageView();
      sessionStorage.clear();
      localStorage.clear();
      const urlParams = new URLSearchParams(window.location.search);
        sessionStorage.setItem("SID", urlParams.get("SID") || '');
    },
    methods: {
      go(flow?: string) {
        if(flow && flow === 'debt'){
          this.$router.push("/cuestionario?flow=debt");
        }
        else
          this.$router.push("/cuestionario");
      },
    },
  });
