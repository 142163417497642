import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/preguntas-frecuentes',
    name: 'Faq',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/prestamos-personales',
    name: 'Loan',
    component: () => import('../views/Loan.vue')
  },
  {
    path: '/terminos-y-condiciones',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/aviso-de-privacidad',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/sanadores-deuda',
    name: 'Healers',
    component: () => import('../views/Healers.vue')
  },
  {
    path: '/cuestionario',
    name: 'Steps',
    component: () => import('../views/Steps.vue')
  },
  {
    path: '/resultados',
    name: 'Results',
    component: () => import('../views/Results.vue')
  },
  {
    path: '/aprobado',
    name: 'Approved',
    component: () => import('../views/Approved.vue')
  },
  {
    path: '/fintonic',
    name: 'Fintonic',
    component: () => import('../views/Fintonic.vue')
  },
  {
    path: '/landing/:landingId',
    name: 'Landgin',
    component: () => import('../views/Landing.vue')
  },
  {
    path: '/anuncia-tu-marca',
    name: 'Brand',
    component: () => import('../views/Brand.vue')
  },
  {
    path: '/404',
    name: 'Error404',
    component: () => import('../views/Error404.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
  },
  linkActiveClass: 'active'
})
export default router
